<template>
  <div>
    <TABS :tabChange="onCallbackTab" :list="tabsArr"></TABS>
    <TABLE :buttonChange='onCallbackButton' :isShowSele="false" :list="tableArr" :data="dataArr"></TABLE>
    <PAGE :pageChange='onCallbackPage' :limitChange='onCallbackLimit' :count="count"></PAGE>

    <add-type :doneChange='onCallbackDone' ref="addT"></add-type>

  </div>
</template>

<script>
import TABS from '@/components/tabs' // 横向 button
import TABLE from '@/components/table' // 列表
import PAGE from '@/components/page' // 分页
import addType from '../components/addType'

import { getExamTestQuesType, delExamTestQuesType, updExamTestQuesType, addExamTestQuesType, getExamTestQuesTypeDetail } from '@/api/safety'
export default {
  name: 'SafetyExamTestPaperType',
  components: {
    TABS,
    TABLE,
    PAGE,
    addType,

  },
  data() {
    return {
      tabsArr: [
        {
          type: 'primary',
          name: '添加分类',
          func: 'openAddType',
        },
      ], // 标签列表
      tableArr: [
        {
          prop: 'name',
          label: '分类名称',
          width: '1300',
        },
        {
          prop: '',
          label: '操作',
          width: '',
          type: 'but',
        },
      ], // 表头列表
      dataArr: [], // 数据列表
      butnArr: [
        {
          type: '',
          name: '编辑',
          func: 'editAddType',
        },
        {
          type: 'danger',
          name: '删除',
          func: 'removeExamTestQuesType',
        },
      ], // 数据-操作列表
      count: 0, // 总页数
      formData: {
        page: 1,
        limit: 10,
      },
    }
  },
  created() {},
  mounted() {
    this._loadData()
  },
  methods: {
    _loadData: function () {
      this.dataArr = []
      getExamTestQuesType(this.formData).then((res) => {
        const { result } = res

        this.count = result.count
        result.data.forEach(item => {
          item.buttons = JSON.parse(JSON.stringify(this.butnArr))
        })
        this.dataArr = result.data
      })
    },

    // 打开分类
    openAddType: function() {
      this.$refs.addT.open()
    },

    // 获取详情
    editAddType: function({ id }) {
      getExamTestQuesTypeDetail(id).then(res => {
        const { result } = res
        this.$refs.addT.open(result)
      })
    },

    // 添加分类
    addType: function(data) {
      addExamTestQuesType(data).then(res => {
        this.$toast(res.message)
        this._loadData()
      })
    },

    // 修改分类
    editType: function(data) {
      updExamTestQuesType(data).then(res => {
        this.$toast(res.message)
        this._loadData()
      })
    },

    // 删除考题
    removeExamTestQuesType: function({ id }) {
      delExamTestQuesType(id).then(res => {
        this.$toast(res.message)
        this._loadData()
      })
    },

    // 收到完成回调
    onCallbackDone: function(type, data) {
      if (type === 'add') this.addType(data)
      else this.editType(data)
    },

    // 收到 table内方法 回调
    onCallbackButton: function(item, func) {
      this[func](item)
    },

    // 收到 tabs 回调
    onCallbackTab: function ({ item, index }) {
      this[item.func]()
    },

    // 收到改变页码回调
    onCallbackPage: function(page) {
      this.formData.page = page
      this._loadData()
    },

    // 收到改变一页数量回调
    onCallbackLimit: function(limit) {
      this.formData.limit = limit
      this._loadData()
    },
  },
}
</script>

<style lang="stylus" scoped></style>
